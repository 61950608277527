import { ScheduleAppointmentPopupProps } from '~/components/schedule/components/ScheduleAppointmentPopup';
import { usePopupContext } from '~/components/interactive/Popup';
import { useEffect, useState } from 'react';
import type { ApiOrderContact } from '~/routes/api+/order.$order.contacts';
import { addressToFull, DisplayData } from '~/lib/model';
import DisplayDataGrid from '~/components/model/DisplayDataGrid';
import ContactGrid from '~/components/model/ContactGrid';
import { Duration } from 'luxon';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import Tag from '~/components/Tag';
import { useTenantWrapper } from '~/tenants/common/TenantContextProvider';

function Field({ fields, name }: { fields: DisplayData[]; name: string }) {
  const field = fields.find((f) => f.name === name);

  if (!field) {
    return null;
  }

  return <DisplayDataGrid data={[field]} full expand />;
}

export default function TwtScheduleAppointmentPopup({
  order,
  start,
  duration,
  tags,
  jobs,
}: ScheduleAppointmentPopupProps<TwtOrderContext>) {
  const popup = usePopupContext();
  const tenant = useTenantWrapper();
  const [{ contacts }, setContacts] = useState<{ loading: boolean; contacts: ApiOrderContact[] }>({
    loading: true,
    contacts: [],
  });

  useEffect(() => {
    fetch(`/api/order/${order.order_id}/contacts`).then(async (r) => {
      setContacts({
        loading: false,
        contacts: await r.json<ApiOrderContact[]>(),
      });
    });
  }, []);

  useEffect(() => {
    if (contacts.length > 0) {
      popup.recalculate();
    }
  }, [contacts.length]);

  const data = [
    ...order.buyer_preferred_provider.map((p) => ({
      name: 'Preferred Provider',
      value: tenant.provider(p).first,
      simple: tenant.provider(p).first,
    })),
    ...order.fields.filter((f) => f.simple),
    ...jobs
      .map((j) => j.fields)
      .flat()
      .filter((j) => j.simple),
    ...order.requested
      .map((r) => r.type === 'custom' && r.notes)
      .filter((f) => !!f)
      .map((r) => ({
        name: 'Custom Request',
        value: r,
        simple: r,
      })),
  ];

  return (
    <div className="bg-white p-2 shadow-2xl rounded-md m-2 text-base max-w-2xl space-y-2">
      {tags && tags.length > 0 && (
        <div className="space-x-1">
          {tags.map((tag) => (
            <Tag key={tag.name} text={tag.name} color={tag.color} />
          ))}
        </div>
      )}

      {start && (
        <div>
          <strong>Time:</strong> {start.toFormat('h:mm')} -{' '}
          {start.plus(Duration.fromISO(duration)).toFormat('h:mm a ZZZZ')}
        </div>
      )}

      <div>
        <strong>Jobs:</strong> {jobs.map((j) => j.name).join(', ')}
      </div>

      <div>
        <strong>Order #{order.order_number}:</strong> {addressToFull(order.address)}
      </div>

      <div>
        <strong>
          {order.buyer_name}
          {order.buyer_parent && <span className="text-sm">({order.buyer_parent})</span>}
        </strong>
        <ContactGrid contacts={contacts} full customer={order.buyer_name} first />
      </div>

      <div>{data.map((d) => d.simple ?? d.value).join(' - ')}</div>

      <Field fields={order.fields} name="Special Instructions" />
    </div>
  );
}
