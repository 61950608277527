import Card from '~/components/layout/Card';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import Heading from '~/components/layout/Heading';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import Message from '~/components/Message';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { useRef } from 'react';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';

export default function TwtOrderTimes({ context }: { context: TwtOrderContext }) {
  const hasPhotos = context.jobs.some((job) => job.performable_id === 'photo');

  const twilight = context.jobs.some((job) => job.performable_id === 'photo' && job.metadata.twilight);
  const matterport = context.jobs.some((job) => job.performable_id === '3d');
  const floorplan = context.jobs.some((job) => job.performable_id === 'floorplan');
  const aerial = context.jobs.some((job) => job.performable_id === 'aerial');
  const video = context.jobs.some((job) => job.performable_id === 'video');

  const hasNonRush = twilight || matterport || floorplan || aerial || video;
  const downgrade = useRef(!hasPhotos && context.metadata.speed !== 'standard');

  let restrict = <></>;

  if (downgrade.current) {
    restrict = (
      <>
        <Message type="error">
          Rush and priority delivery is only available for Photos, you will be downgraded to standard delivery.
        </Message>
        <ZodFieldHidden name="speed" value="standard" />
      </>
    );
  } else if (hasNonRush && context.metadata.speed !== 'standard') {
    restrict = (
      <Card className="space-y-2">
        <Message type="warning">
          Rush and priority delivery is only available for Photos, all other services will be delivered within 24 hours.
        </Message>
        <FormHorizontal label="Change Delivery Speed">
          <ZodFieldSelect
            name="speed"
            options={[
              { label: 'Standard (6PM Next Day)', value: 'standard' },
              { label: 'Rush (8PM Same Day)', value: 'rush' },
              { label: 'Priority (9AM Next Day)', value: 'priority' },
            ]}
          />
        </FormHorizontal>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <Heading title="3. Additional Information" />
      {restrict}
      <div>
        <Card className="flex items-center space-x-2">
          <ZodFieldCheckbox name="fallback" id="fallback" />
          <label htmlFor="fallback" className="cursor-pointer select-none">
            Schedule appointment with an alternative team member if the chosen team members are unavailable.
          </label>
        </Card>
      </div>
    </div>
  );
}
